<template>
  <div class="hello" v-if="flag == 'PC'">
    <img src="../assets/background.png" alt="" class="backgroundStyle" id="type-0"/>
    <div class="header">
      <div class="headerList">
        <div class="headerLeft">
          <img src="../assets/logoF.png" alt="" style="width: 40px" />
          <h1
            style="
              margin-top: 5px;
              margin-bottom: 5px;
              font-size: 25px;
              color: #fff;
              margin-left: 5px;
            " 
          >
            大川长风
          </h1>
        </div>
        <ul class="headerRight">
          <li v-for="(item, index) in headeList" :key="index" ><a :href="'#type-'+index">{{item}}</a></li>
          <li>
            <a
              href="https://dc.daccf.com/"
              target="_blank"
              style="color: black; border-radius: 5px"
              class="btn"
              >登录后台</a
            >
          </li>
        </ul>
      </div>
    </div>
    <!-- 头部导航 -->
    <div class="util">
      <div class="utilLeft">
        <h1>
          <strong>微信生态私域工具</strong>
          <strong>定制开发平台</strong>
        </h1>
        <p>用完即走的理念,触手可及的梦想</p>
        <a href="https://dc.daccf.com/" target="_blank" class="btn">
          免费使用
        </a>
      </div>

      <div>
        <img
          src="../assets/cp2.png"
          alt=""
          style="width: 500px; height: 278px;border-radius:12px"
        />
      </div>
    </div>
    <!-- 为你提供 -->
          <p class="title" style="margin-top:-170px" id="type-1">为你提供</p>
    <div class="componentStyle">
      <img
        src="../assets/banner_conversion.png"
        alt=""
        style="width: 690px; height: 320px"
      />
      <div>
        <h3>模板定制</h3>
        <p>多个行业模版可选，极速部署上线。模版均可任意修改和定制新模版</p>
        <h3>插件定制</h3>
        <p>上百个插件可选，总有一个适合你。也可以针对自己业务定制专属插件</p>
      </div>
    </div>
    <div class="componentStyle">
      <div>
        <h3>多元生态</h3>
        <p>默认基于微信生态，但不止于微信，也可以定制其他生态的模版和插件</p>
        <h3>API对接</h3>
        <p>已对接客如云、达达等第三方接口，不限第三方，有接口即可对接</p>
      </div>
      <img
        src="../assets/banner_multi.png"
        alt=""
        style="width: 690px; height: 320px"
      />
    </div>
    <!-- 业界合作 -->
    <div>
      <div style="position: relative;width:100%;margin-top:80px" >
        <p class="title" id="type-2">业界合作</p>
        <img src="../assets/background2.png" alt="" class="yejieStyle" />
        <ul class="industryStyle">
          <li v-for="(item, index) in industryList" :key="index">
            <img
              :src="item.icon"
              alt=""
              style="
                width: 50px;
                height: 50px;
                border-radius: 100px;
                margin-right: 10px;
              "
            />
            {{ item.title }}
          </li>
        </ul>
      </div>
    </div>
    <!-- 应用案例 -->
    <div style="width: 100%;margin-top:80px" id="section1">
      <div>
        <p class="title" id="type-3">应用案例</p>
        <div class="caseStyle">
          <div style="margin-top: 60px">
            <p style="margin: 20px 0; font-size: 20px">大川长风</p>
            <p style="margin: 20px 0">微信生态私域工具定制开发平台</p>
            <img
              src="../assets/logo.jpg"
              alt=""
              style="width: 150px; height: 150px"
            />
          </div>
          <ul>
            <li v-for="(item, index) in caseList" :key="index">
              <img
                :src="item.icon"
                alt=""
                style="width: 210px; height: 430px"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 尾部导航 -->
    <div class="foot" id="type-4">
      <div>
        <ul>
          <li
            v-for="(item, index) in footLeftList"
            :key="index"
            
          >
            {{ item.title }}{{ item.content }}
          </li>
        </ul>
        <ul>
          <li v-for="(item, index) in footCenterList" :key="index">
            <a
              :href="item.href"
              target="_blank"
              class="jumpStyle"
              style="color: rgba(255, 255, 255, 0.6)"
              >{{ item.content }}</a
            >
          </li>
        </ul>
        <div class="liStyle">
          © 2019-2022 杭州大川长风网络科技有限公司 版权所有
          <a
            href="https://beian.miit.gov.cn/#/Integrated/index"
            target="_blank"
            style="color: rgba(255, 255, 255, 0.6)"
            class="jumpStyle"
            >浙ICP备15000106号</a
          >
        </div>
      </div>
      <div>
        <img
          src="../assets/gzh.jpg"
          alt=""
          style="width: 130px; height: 130px"
        />
       <div style="line-height:0px">
         关注我们
       </div>
      </div>
    </div>
    <!-- 锚点链接 -->
    <div class="pointStyle">
      <ul>
        <!-- <li v-for="(item,index) in pointList" :key="index">{{item.title}}
        </li> -->
        <li>
          联系客服
          <div class="box">
            <img
              src="../assets/wechat_code.png"
              alt=""
              style="width: 130px; height: 130px"
            />
          </div>
        </li>
        <li>
          查看案例
          <div class="box1">
            <img
              src="../assets/logo.jpg"
              alt=""
              style="width: 130px; height: 130px"
            />
          </div>
        </li>
        <li>
          商户助手
          <div class="box2">
            <img
              src="../assets/promotion.jpg"
              alt=""
              style="width: 130px; height: 130px"
            />
          </div>
        </li>
        <li>
          <a href="https://dc.daccf.com/" target="_blank" style="color: white"
            >登录后台</a
          >
        </li>
      </ul>
    </div>
  </div>
  <!-- 移动端 -->
  <div v-if="flag == 'move'">
    <div class="container">
      <div class="head">
        <div class="headAuto">
          <div class="headLeft">
            <img
              src="https://app.daccf.com/cdn/01/15c1aa6da2d7b26bcada20b3f17c17ff.png"
              alt=""
              style="width: 40px"
            />
            <h1
              style="
                margin-top: 5px;
                margin-bottom: 5px;
                font-size: 18px;
                color: #333;
                margin-left: 5px;
              "
            >
              大川长风
            </h1>
          </div>
          <!-- <a href="https://dc.daccf.com/" target="_blank"><h1 style="color:#333;font-size:18px">登录</h1></a> -->
        </div>
      </div>
      <div class="main">
        <div>
          <h1 style="font-size: 24px; text-align: center; padding-bottom: 10px">
            <strong>微信生态私域工具</strong><br />
            <strong>定制开发平台</strong>
          </h1>
          <p style="margin-top: 10px">为品牌赋能，打造专属业务私域营销工具</p>
          <img
            src="../assets/cp2.png"
            alt=""
            style="width: 384px; height: 230px; margin-left: -35px"
          />
          <a
            href="https://dc.daccf.com/"
            target="_blank"
            class="btn"
            style="line-height: 30px; height: 30px; margin: 0 auto"
          >
            免费使用
          </a>
        </div>

        <div v-for="(item, index) in boxList" :key="index" class="boxStyle">
          <div>
            <span class="boxListTitle">{{ item.title }}</span>
          </div>
          <div>{{ item.content }}</div>
          <div><img src="" alt="" /></div>
        </div>

        <div>
          <h2>一站式定制解决方案</h2>
          <img
            src="../assets/todo2.png"
            alt=""
            style="width: 384px; height: 230px; margin-left: -35px"
          />
          <h2>无需开发和运维</h2>
          <p style="margin-top: 10px">
            为客户节省大量的开发成本和运维成本，让您的项目不再因为技术短板而止步
          </p>
          <img
            src="../assets/todoF2.png"
            alt=""
            style="width:384px;height:230;px;margin-left:-35px"
          />
        </div>
      </div>
      <div class="footer">
        <div>
          <ul>
            <li>
              <a
                href="https://fuwu.weixin.qq.com/profile/sq_of20t5McgEq0cquvEMZhImbo1cgc/miniProgramDevelop"
                target="_blank"
                style="color: white"
                >大川长风微信服务商</a
              >
            </li>
            <li>
              © 2019-2022 杭州大川长风网络科技有限公司<br />
              <a
                href="https://beian.miit.gov.cn/#/Integrated/index"
                target="_blank"
                style="color: white"
                >浙ICP备15000106号</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from "vue";
export default {
  name: "website",
  //接受子组件传来的参数
  props: {
    msg: String,
  },
  setup() {
    let headeList = reactive([
      "私域方案",
      "为你提供",
      "业界合作",
      "应用案例",
      "联系我们",
    ]);
    let boxList = reactive([
      {
        title: "模板定制",
        content: "多个行业模版可选，极速部署上线。模版均可任意修改和定制新模版",
        img: "https://daccf.com/static/img/intro_icon_01.png",
      },
      {
        title: "插件定制",
        content:
          "上百个插件可选，总有一个适合你。也可以针对自己业务定制专属插件",
        img: "https://daccf.com/static/img/intro_icon_02.png",
      },
      {
        title: "多元生态",
        content:
          "默认基于微信生态，但不止于微信，也可以定制其他生态的模版和插件",
        img: "https://daccf.com/static/img/intro_icon_03.png",
      },
      {
        title: "API对接",
        content: "已对接客如云、达达等第三方接口，不限第三方，有接口即可对接",
        img: "		https://daccf.com/static/img/intro_icon_04.png",
      },
    ]);
    let footLeftList = reactive([
      { title: "私域工具:", content: "打造专属私域工具" },
      { title: "营销组件:", content: "共享私域营销组件" },
      { title: "定制开发:", content: "突破常规定制思维" },
      { title: "行业方案:", content: "全行业整合微服务" },
    ]);
    let footCenterList = reactive([
      { content: "友情链接:" },
      {
        content: "七牛云存储",
        href: "https://portal.qiniu.com/signup?code=1he7cef7g39zm",
      },
      {
        content: "阿里云优惠推荐",
        href: "https://www.aliyun.com/minisite/goods?userCode=gqzy7cm1",
      },
      {
        content: "腾讯云优惠推荐",
        href: "https://cloud.tencent.com/act/pro/cps_3?fromSource=gwzcw.6688284.6688284.6688284&utm_medium=cps&utm_id=gwzcw.6688284.6688284.6688284&cps_key=0628c2ee9d17c823e5c1e1eca0236967",
      },
      {
        content: "大川长风微信服务商",
        href: "https://fuwu.weixin.qq.com/profile/sq_of20t5McgEq0cquvEMZhImbo1cgc/miniProgramDevelop",
      },
    ]);
    let industryList = reactive([
      { icon: require("../assets/logo_01.jpeg"), title: "小芳超市" },
      { icon: require("../assets/logo_02.jpeg"), title: "九橙果业" },
      { icon: require("../assets/logo_03.jpeg"), title: "海鸟生鲜" },
      { icon: require("../assets/logo_04.jpeg"), title: "浙里严选" },
      { icon: require("../assets/logo_05.jpeg"), title: "建工讲堂" },
      { icon: require("../assets/logo_06.png"), title: "贝迪宠物管家" },
      { icon: require("../assets/logo_15.jpeg"), title: "建院集团" },
      { icon: require("../assets/logo_10.png"), title: "小豆豆服饰" },
      { icon: require("../assets/logo_12.png"), title: "舌尖安牛骨汤小笼包" },
      { icon: require("../assets/logo_13.png"), title: "潮丰汇服饰" },
      { icon: require("../assets/logo_07.jpeg"), title: "菜园里" },
      { icon: require("../assets/logo_09.jpeg"), title: "建工筑考通" },
      { icon: require("../assets/logo_08.jpeg"), title: "潼潼十岁" },
      { icon: require("../assets/logo_11.jpeg"), title: "兔跳跳回收" },
      { icon: require("../assets/logo_14.jpeg"), title: "梦未央" },
    ]);
    let caseList = reactive([
      { icon: require("../assets/p1.jpg") },
      { icon: require("../assets/p2.jpg") },
      { icon: require("../assets/p3.jpg") },
      { icon: require("../assets/p4.jpg") },
    ]);
    let pointList = reactive([{ title: "联系客服" }, { title: "商户助手" }]);
    let flag = ref();
    var sUserAgent = navigator.userAgent.toLowerCase();
    if (
      /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(
        sUserAgent
      )
    ) {
      console.log("移动端");
      flag.value = "move";
    } else {
      console.log("PC端");
      flag.value = "PC";
    }
    return {
      //必须返回 模板中才能使用
      headeList,
      boxList,
      footLeftList,
      footCenterList,
      industryList,
      pointList,
      flag,
      caseList,
    };
  },
};
</script>
  
<style scoped rel="stylesheet/less" lang="less" >
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
    cursor: pointer;
}
a {
  text-decoration: none;
}
.backgroundStyle {
  width: 100%;
  // height: 700px;
}
.componentStyle {
  display: flex;
  justify-content: space-around;
  width: 80%;
  margin: 30px auto;
  flex-flow: row wrap;
}
.componentStyle div p {
  margin-top: 10px;
}
.headerLeft {
  float: right;
  font-size: 18px;
  line-height: 20px;
  height: 100%;
  display: flex;
}
.headerRight {
  float: right;
  padding: 20px 0;
  margin-right: 10px;
  display: flex;
  font-size: 18px;
  height: 100%;
  // color: #fff;
  display: flex;
  align-items: center;
  li{
    margin: 0 30px;
    a{
        color: #fff;
    }
  }
}
.header {
  width: 100%;
  height: 70px;
  position: fixed;
  top: 0;
  z-index: 99999;
  background-color: #0000c2;
}
.headerList {
  display: flex;
  justify-content: space-around;
  width: 80%;
  margin: 0 auto;
  align-items: center;
}
.util {
  position: absolute;
  display: flex;
  justify-content: space-around;
  width: 80%;
  margin-top: 50px;
  top: 100px;
  left: 10%;
}
.utilLeft {
  // margin-right: 130px;
  color: #fff;
  line-height: 100px;
}
.industryStyle {
  display: flex;
  flex-flow: row wrap;
  //  position: absolute;
  top: 100px;
  width: 70%;
  //  left: 15%;
  justify-content: space-around;
  margin: 0 auto;
}
.yejieStyle {
  position: absolute;
  left: 0;
  width: 100%;
  height: 80%;
}
.industryStyle li {
  width: 230px;
  height: 150px;
  background: #2ceb85;
  line-height: 150px;
  margin: 40px auto;
  -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
  background: #fff;
  -moz-border-radius: 10px;
  border-radius: 10px;
  text-align: center;
  overflow: hidden;
  z-index: 8888;
  display: flex;
  justify-content: center;
  align-items: center;
}
.industryStyle li:hover {
  opacity: 0.8;
}
.btn {
  background: -webkit-linear-gradient(331deg, #00b2bd 11%, #2ceb85 55%);
  width: 130px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  border-radius: 40px;
  letter-spacing: 2px;
  text-indent: 2px;
  text-align: center;
  color: #fff;
  display: block;
  margin: 0px auto;
}
.personUtil {
  display: flex;
  justify-content: space-around;
}
.foot {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  background: black;
  text-align: center;
  line-height: 50px;
  margin-top: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 300px;
  width: 100%;
}
.liStyle:hover {
  color: white;
  cursor: pointer;
}
.jumpStyle:hover {
  color: white !important;
  cursor: pointer !important;
}
.caseStyle {
  display: flex;
  width: 70%;
  margin: 0 auto;
  justify-content: space-around;
  div {
    width: 400px;
    height: 313px;
    background: #2ceb85;
    -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
    -moz-border-radius: 10px;
    border-radius: 10px;
    z-index: 99999;
    p {
      color: #fff;
    }
  }
  .caseStyle ul {
    margin: 30px auto;
    display: flex;
    li {
      margin-right: 10px;
    }
  }
}
.title {
  font-size: 30px;
  letter-spacing: 20px;
  font-weight: bold;
  margin-bottom: 80px;
}
.pointStyle {
  position: fixed;
  right: 0;
  bottom: 100px;
  z-index: 999999;
  ul li {
    height: 60px;
    width: 60px;
    margin-bottom: 2px;
    background-color: #2e6cf6;
    display: block;
    line-height: 60px;
    text-align: center;
    color: #fff;
    font-size: 12px;
  }
}
.pointStyle ul li:nth-of-type(1) {
  border-radius: 10px 10px 0 0;
}
.pointStyle ul li:nth-of-type(1):hover > .box {
  display: block;
}
.pointStyle ul li:nth-of-type(2):hover > .box1 {
  display: block;
}
.pointStyle ul li:nth-of-type(3):hover > .box2 {
  display: block;
}
.pointStyle ul li:nth-of-type(4) {
  border-radius: 0px 0px 10px 10px;
}
.pointStyle ul li:hover {
  cursor: pointer;
}
.box {
  position: fixed;
  width: 200px;
  height: 200px;
  background: white;
  right: 100px;
  bottom: 60px;
  display: none;
  img {
    margin-top: 30px;
  }
}
.box1 {
  position: fixed;
  width: 200px;
  height: 200px;
  background: white;
  right: 100px;
  bottom: 60px;
  display: none;
  text-align: center;
  line-height: 200px;
  img {
    margin-top: 30px;
  }
}
.box2 {
  position: fixed;
  width: 200px;
  height: 200px;
  background: white;
  right: 100px;
  bottom: 60px;
  display: none;
  text-align: center;
  line-height: 200px;
  img {
    margin-top: 30px;
  }
}
.container {
  display: flex;
  height: 100vh;
  flex-direction: column;
  .head,
  .footer {
    // height: 60px;
    width: 100%;
  }
  .head {
    width: 100%;
    box-shadow: 0 2px 8px 0 rgba(45, 45, 45, 0.09);
    height: 50px;
  }
  .headAuto {
    width: 80%;
    margin: 5px auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .headLeft {
    display: flex;
    align-items: center;
  }
  .footer {
    background: #44D;
    ul li {
      padding: 5px 0;
      color: white;
      font-size: 14px;
    }
  }
  .main {
    width: 100%;
    flex: 1;
    overflow-y: scroll;
  }
  .main div {
    // background: #8bc34a;
    // height: 200px;
    margin: 20px auto;
    width: 300px;
  }
  .boxStyle {
    height: 159px;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #bedbff;
    padding: 20px;
  }
  .boxListTitle {
    font-size: 18px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: 700;
    color: #1e2330;
  }
}
</style>
